import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as dat from "lil-gui";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { TextGeometry } from "three/addons/geometries/TextGeometry.js";
import gsap from "gsap";

/**
 * Base
 */
// Debug
// const gui = new dat.GUI();

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

// // Axies helper
// const axesHelper = new THREE.AxesHelper()
// scene.add(axesHelper)

let mouseX = 2;
let mouseY = 2;

let tiltX = null;
let tiltY = null;
let tiltZ = null;

/**
 *
 * Gyroscope registers real time the actual movement of the device
 *
 *
 * */

// let gyroscope = new Gyroscope({ frequency: 60 });
// gyroscope.addEventListener("reading", (e) => {
//   tiltX = Math.round(gyroscope.x * 2);
//   tiltY = Math.round(gyroscope.y * 2);
//   tiltZ = Math.round(gyroscope.z * 2);

//   deviceOrientationHandler(tiltX, tiltY, tiltZ);
//   document.getElementById(
//     "gyroMessage"
//   ).textContent = `X-axis ${tiltX}.Y-axis ${tiltY}. Z-axis ${tiltZ}.`;
// });

// gyroscope.start();

// const deviceOrientationHandler = (tiltX, tiltY, tiltZ) => {
//   mouseX = tiltX;
//   mouseY = tiltY;
// };

/**
 *
 * Device Orientation Event
 *
 * */




window.addEventListener(
  "deviceorientation",
  function (event) {
    if (event.alpha) {
      // Do something with event.alpha
      tiltX = Math.round(event.beta);
      tiltY = Math.round(event.gamma);
      tiltZ = Math.round(event.alpha);
      mouseX = (tiltX - windowHalfX) / 100;
      mouseY = (tiltY - windowHalfY) / 100;
    }

    document.getElementById(
      "gyroMessage"
    ).textContent = `Beta: ${tiltX}. Gamma ${tiltY}. Alpha: ${tiltZ}.`;
    // process event.alpha, event.beta and event.gamma
  },
  true
);



// alpha = 360
// beta = 180
// gamma = 90

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader();
const matcapTexture = textureLoader.load("/textures/matcaps/8.png");

/* Fonts */

const fontLoader = new FontLoader();
let text;
fontLoader.load("/fonts/helvetiker_regular.typeface.json", (font) => {
  let textGeometry;
  if (sizes.width <= 412) {
    textGeometry = new TextGeometry("the \nrenderist \nstudio", {
      font,
      size: sizes.width * 0.0005,
      height: 0.2,
      curveSegments: 30,
      bevelEnabled: true,
      bevelThickness: 0.05,
      bevelSize: 0.015,
      bevelOffset: 0,
      bevelSegments: 30,
    });
  } else {
    textGeometry = new TextGeometry("the \nrenderist \nstudio", {
      font,
      size: 0.5,
      height: 0.2,
      curveSegments: 30,
      bevelEnabled: true,
      bevelThickness: 0.05,
      bevelSize: 0.02,
      bevelOffset: 0,
      bevelSegments: 30,
    });
  }
  // textGeometry.computeBoundingBox()
  // textGeometry.translate(
  //    - textGeometry.boundingBox.max.x * 0.5,
  //    - textGeometry.boundingBox.max.z * 0.5,
  //    - textGeometry.boundingBox.max.z * 0.5
  // )

  textGeometry.center();

  const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture });
  text = new THREE.Mesh(textGeometry, material);
  scene.add(text);

  const donatGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45);
  const boxGeometry = new THREE.BoxGeometry(0.5, 0.5, 0.5);

  for (let i = 0; i < 100; i++) {
    const donat = new THREE.Mesh(donatGeometry, material);
    const box = new THREE.Mesh(boxGeometry, material);

    donat.position.x = (Math.random() - 0.5) * 30;
    donat.position.y = (Math.random() - 0.5) * 30;
    donat.position.z = (Math.random() - 0.5) * 30;

    box.position.x = (Math.random() - 0.5) * 30;
    box.position.y = (Math.random() - 0.5) * 30;
    box.position.z = (Math.random() - 0.5) * 30;

    donat.rotation.x = Math.random() * Math.PI;
    donat.rotation.y = Math.random() * Math.PI;

    box.rotation.x = Math.random() * Math.PI;
    box.rotation.y = Math.random() * Math.PI;

    const scale = Math.random();

    donat.scale.set(scale, scale, scale);

    scene.add(donat, box);
  }
});

// /**
//  * Object
//  */
// const cube = new THREE.Mesh(
//     new THREE.BoxGeometry(1, 1, 1),
//     new THREE.MeshBasicMaterial()
// )

// scene.add(cube)

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

let windowHalfX = sizes.width / 2;
let windowHalfY = sizes.height / 2;

window.addEventListener("mousemove", onMouseMove);

function onMouseMove(event) {
  mouseX = (event.clientX - windowHalfX) / 100;
  mouseY = (event.clientY - windowHalfY) / 100;
}

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.x = 5;
camera.position.y = 5;
camera.position.z = 5;

scene.add(camera);

// Controls
// let controls;
// if (sizes.width <= 412) {
//   controls = new OrbitControls(camera, canvas);
//   controls.autoRotate = true;
//   controls.enableDamping = true;
// }

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

const mapRange = (value, fromMin, fromMax, toMin, toMax) => {
  // Ensure the value is within the input range
  value = Math.min(Math.max(value, fromMin), fromMax);
  
  // Calculate the normalized position of the value in the input range
  const normalizedPosition = (value - fromMin) / (fromMax - fromMin);
  
  // Map the normalized position to the output range
  const mappedValue = normalizedPosition * (toMax - toMin) + toMin;
  
  return mappedValue;
};

/**
 * Animate
 */
const clock = new THREE.Clock();

const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  const cameraRotationSpeed = Math.PI; // Adjust the rotation speed as needed

  const mappedYAlpha = mapRange(tiltZ, 0, 360, -1, 1);
  const mappedGamma = mapRange(tiltY, 0, 90, -5, 5);
  const mappedBeta = mapRange(tiltX, 0, 180, -1, 1);

  // if (text) {
  //   text.rotation.y = mappedYAlpha * Math.PI;
  //   // text.rotation.y = Math.cos(elapsedTime * cameraRotationSpeed) * 0.5;
  // }

 
  // Apply rotation to the camera's position using cosine function

  // const cameraRotationAngle = elapsedTime * cameraRotationSpeed;
  // const cameraRadius = 5; // Adjust the radius of the rotation
  // camera.position.x = cameraRadius * Math.cos(cameraRotationAngle) * 1.1;
  // // camera.position.z = cameraRadius * Math.sin(cameraRotationAngle);

  if (!tiltX) {
    camera.position.x += (mouseX - camera.position.x) * 0.05;
    camera.position.y += (-mouseY - camera.position.y) * 0.05;
    camera.position.z += (mouseY - camera.position.z) * 0.009;
    document.getElementById("gyroMessage").textContent =
      "No gyroscope detected on your device.";
    console.log(mouseX, mouseY);
  } else {
    // Update the camera's position based on gyroscope rotation
    const cameraRotationSpeed = 0.5; // Adjust the rotation speed as needed
    const cameraRadius = 1; // Adjust the radius of the rotation
    // camera.position.x = tiltY;
    // camera.position.y = mouseY;
    camera.position.x += (mappedYAlpha - camera.position.x);
    camera.position.y += (mappedBeta - camera.position.y);
    camera.position.z += (-mappedBeta - camera.position.z);

  
    camera.lookAt(scene.position);
  }

  // if (sizes.width > 412) {
  //   camera.position.x += (mouseX - camera.position.x) * 0.05;
  //   camera.position.y += (-mouseY - camera.position.y) * 0.05;

  // }

  // Check if device supports gyroscope and is a mobile device

  camera.lookAt(scene.position);

  // // Update controls
  // if (sizes.width <= 412) {
  //   controls.update();
  // }

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
